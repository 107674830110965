import {
  Link,
  Box,
  AppBar,
  Container,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React from "react";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
}

export function AppToolbar() {
  return (
    <ElevationScroll>
      <AppBar color="default">
        <Container>
          <Toolbar disableGutters>
            <img
              src="papasitelogo.png"
              width={200}
              height={200}
              alt="Мастерская в Южном"
            />
            <Box display="flex" flexDirection="column" alignItems="left">
              <Typography sx={{ mx: 2}} variant="h6">
                <Link underline="none" color="inherit" href="tel:+79040289741">
                  +7 (904) 028-97-41
                </Link>{" "}
                • г. Тверь, пр. Октябрьский, 99, секция 4<br/>
                Время работы: вторник, среда, пятница, суббота, с 13:00 до 18:30. По договоренности время работы может быть изменено.
                Перед посещением позвоните, чтобы убедиться, что мастер на месте.
              </Typography>
            </Box>
            <span style={{ flexGrow: 1 }} />
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}
